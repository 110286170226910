@import '../../../../static/styles/variables';
@import '../../../../static/styles/mediaqueries';


.gifts {
    width: 100%;
    height: 620px;
    overflow: hidden;
    background-color: $primary-bg;

    @include media(m-device) {
        height: 500px;
    }

    &__grid {
        display: flex;
        justify-content: space-between;
        width: 110%;
        height: calc(408px * 3);
        margin-left: -5%;
        margin-top: -5%;


        @include media(m-device) {
            height: calc(500px * 4);
            flex-wrap: wrap;
            width: 150%;
            margin-left: -25%;
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transition: transform .2s linear;

        @include media(m-device) {
            width: 25%;
        }

        &:nth-of-type(odd) {
            margin-top: -5%;
        }

        &:nth-of-type(even) {
            margin-bottom: -5%;
        }
    }

}

.gift {
    width: calc(100% - 16px);
    background-color: #fff;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 6px;

    @include media(m-device) {
        width: calc(100% - 8px);
        height: calc(100% / 8);
        margin: 4px;
        padding: 5px;
    }

    &__img {
      span {
        @include media(m-device) {
            height: 70%;
        }

        &__content{
            padding: 16px;
        }
      }
    }

    h2 {
        font-family: $baderCrompress;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;

        @include media(m-device) {
            font-size: 14px;
        }
    }

    p {
        font-family: $baderNarrow;
        font-size: 16px;
        line-height: 20px;
        color: $light-grey;
        margin-top: 8px;

        @include media(m-device) {
            font-size: 10px;
            line-height: normal;
            padding-bottom: 5px;
        }
    }
}
